import {useContext} from "react";
import {Link, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";

// Assets
import {IckonicLogo} from "../../Assets/SVGs/Logos";

// Components
import HeaderMenu from "../Menus/HeaderMenu";
import ButtonPillGradient from "../Buttons/ButtonPillGradient";

// Global state
import {GlobalInterfaceContext} from "../../Context/GlobalInterfaceContext";
import {UserAuthenticationContext} from "../../Context/UserAuthenticationContext";

// Constants
import {RoutePaths} from "../../Constants/RoutePaths";
import Notifications from "./Notifications";
import SearchBar from "./SearchBar";
import AccountDropDown from "../Menus/AccountDropDown";

const Header = (props: {shouldDisplayNotifyBar: boolean}) => {
    const

        // Context
        globalInterfaceContext = useContext(GlobalInterfaceContext),
        userAuthContext = useContext(UserAuthenticationContext),
        {t} = useTranslation(),
        {shouldDisplayNotifyBar} = props,

        // Misc
        location = useLocation();

    return (
        <header className={`header--global
            ${globalInterfaceContext?.isDarkMode ? 'is-dark-mode' : 'has-light-mode'}
            ${globalInterfaceContext?.mainMenuDesktopMode ? 'has-desktop-menu' : 'has-mobile-menu'}
            ${shouldDisplayNotifyBar && globalInterfaceContext.hasNotificationBar ? 'has-notification-bar' : 'notification-bar-hidden'}
        `}>
            {location.pathname === '/' || (userAuthContext.doesAuthTokenExist() && location.pathname === '/Browse') ?
                (
                    <div className="header__logo">
                        {IckonicLogo()}
                    </div>
                )
                :
                (
                    <Link
                        className="header__logo"
                        to={userAuthContext.doesAuthTokenExist() ? '/Browse' : '/'}
                    >
                        {IckonicLogo()}
                    </Link>
                )
            }

            <div className="header__cover u-full_cover_absolute"/>

            <button
                className={`button--burger ${globalInterfaceContext?.mainMenuOpen ? 'menu-is-active' : 'menu-inactive'}`}
                onClick={() => globalInterfaceContext?.toggleMainMenuOpenState(!globalInterfaceContext?.mainMenuOpen)}
            >
                <span>{t('header-ui-hamburger-button-label')}</span>
            </button>

            <HeaderMenu
                isDarkMode={globalInterfaceContext.isDarkMode}
                menuActive={globalInterfaceContext.mainMenuOpen}
                hasNotificationBar={shouldDisplayNotifyBar && globalInterfaceContext.hasNotificationBar}
            />

            {!userAuthContext.doesAuthTokenExist() &&
                (
                    <ButtonPillGradient
                        link={RoutePaths.SignUp[0]}
                        text="Sign Up"
                        className=""
                        background="linear-gradient(225deg, rgba(187, 54, 184, 1) 0%, #b760b4 100%)"
                        backgroundHover="#b760b4"
                        color=""
                    />
                )
            }

            {userAuthContext.userData.SubscriptionStatus === 0 &&
                (
                    <div className="header__subscribe">
                        <ButtonPillGradient
                            link={RoutePaths.ChoosePlan}
                            text="Subscribe"
                            className=""
                            background="linear-gradient(225deg, rgba(187, 54, 184, 1) 0%, #b760b4 100%)"
                            backgroundHover="#b760b4"
                            color=""
                        />
                    </div>
                )
            }

            {userAuthContext.doesAuthTokenExist() && userAuthContext.userData.SubscriptionStatus !== 0 && (
                <>
                    <SearchBar
                        currentCountryCode="GB"
                    />

                    <Notifications/>
                </>
            )}

            <AccountDropDown
                userData={userAuthContext.userData}
                logOut={userAuthContext.logOutUser}
                tokenExists={userAuthContext.doesAuthTokenExist()}
            />
        </header>
    );
};

export default Header;
